//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import { LocalStorage } from "quasar";
export default {
  name: "Home",
  data() {
    return {
      date:'',
      basicInfo: {
        truename: "",
        store: "",
        date: "",
        paperId:'',
        name:''
      },
      orderId:false,
      paperId:'',
      loading: false,
      showCalendar: false,
      minDate:new Date(this.$moment().subtract(30, 'days'))
    };
  },
  created() {
    console.log(this.$route.query);
    this.basicInfo.paperId = this.$route.query.paperId ? this.$route.query.paperId : '';
    this.basicInfo.name = this.$route.query.title ? this.$route.query.title : '';
  },
  mounted() {
    window.document.title = "新增巡检组";
    this.basicInfo.truename = LocalStorage.getItem("userName");
  },
  activated() {
    window.document.title = "新增巡检组";
  },
  methods: {
    goPage(url){
      console.log(url);
      this.$router.replace(url)
    },
    back(){
      this.$router.go(-1)
    },
    checkStore(e){
      console.log(e);
      return new Promise((resolve) => {
        this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
           "/common/store/listv1",
          {
            "pager": {
              "pageIndex": 1,
              "pageSize": 100,
              "rowsCount": 0,
              "start": 0
            },
            "queryMap": {
              "storeStatus": "",
              "brand": "ZBXN",
              "storeid":e,
              "storeType": "1"
            },
            "queryMapObject": {}
          }
        )
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.code == 0) {
            if(res.data.data.length > 0){
              resolve(true)
            }else{
              resolve(false)
            }
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        })
      });
    },
    chooseCalendar(e){
      if(this.orderId)return
      this.minDate = new Date(this.$moment().subtract(30, 'days'));
      this.showCalendar = true;
    },
    onConfirmCalendar(date) {
        this.basicInfo.wdate = `${date[0].getFullYear()}-${
          date[0].getMonth() + 1
        }-${date[0].getDate()}`;
        this.basicInfo.edate = `${date[1].getFullYear()}-${
        date[1].getMonth() + 1
      }-${date[1].getDate()}`;
      this.date = this.basicInfo.wdate + ' 至 ' + this.basicInfo.edate
      this.showCalendar = false;
    },
    //创建巡检表
    createOrder() {
      let postData = {
        paperId:this.basicInfo.paperId,
        name:this.basicInfo.store.toUpperCase() + this.basicInfo.name,
        storeid: this.basicInfo.store.toUpperCase(),
        wdate: this.basicInfo.wdate,
        edate: this.basicInfo.edate,
      };
      this.loading = true;
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
           "/store/patrol/group/create",
          postData
        )
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.code == 0) {
            Toast("新增成功");
            this.orderId = res.data.data.id;
            this.paperId = res.data.data.paperId;
            // this.$router.go(-1)
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        })
        
    },
  },

};
